@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

.btn {
  @apply py-2 px-6 rounded;
}

.btn-blue {
  @apply bg-blue-500 text-blue-100;
}

.economic-value-card {
  float: left;
  width: 25%;
}

.economic-value-dropdown {
  width: 30%;
}

.economic-value-graph {
  width: 70%;
}

.parallelogram {
  -webkit-transform: skew(-15deg);
  -moz-transform: skew(-15deg);
  -o-transform: skew(-15deg);
  background: #9aca3c;
}

.wrapper {
  display: flex;
  width: 100%;
}

.icon {
  margin-left: auto;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.tabs {
  display: flex;
  justify-content: flex-start;
}

.tabs button {
  background-color: #ced4da;
  border-top: solid;
  border-bottom: 6px;
  border-color: #888888;
  color: #888888;
  cursor: pointer;
  padding: 30px;
  width: 50%;
  transition: all 0.5s ease-out;
}

.tabs button:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.tabs button:disabled {
  background: linear-gradient(245.39deg, #f3f9e7 -35.14%, #e4fbbc 143.06%);
  border-color: #e4fbbc;
  color: rgb(120, 115, 115);
}

.content {
  padding: 10px 10px;
  background-color: white;
}

.backgoundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
}
.backgoundImage2 {
  background-size: cover;
  width: "100vw";
  height: "100vh";
}

div.transbox {
  margin: 30px;
  background-color: #ffffff;
  border: 1px solid black;
  opacity: 0.4;
}
div.transbox p {
  color: #000000;
}
textcolor {
  color: #000000;
}

.h21 {
  word-wrap: break-word;
}

.carousel-caption {
  top: 50%;
  transform: translateY(-50%);
}

.canvas-container {
  height: 100%;
  background-color: #e9ecef;
  min-height: 50vh;
}

.text-oswald-font {
  font-family: "Open Sans";
}

body {
  font-family: "Open Sans";
}
