.App1 {
  font-family: sans-serif;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.custom-btn {
  background: #fff;
  color: black;
  border: 2px solid #d3d3d3;
  border-radius: 1px;
  text-transform: capitalize;
  padding: 10px 80px;
}
.button-blue:hover {
  border: 2px solid #d3d3d3;
  border-radius: 1px;
}

.CheckboxMenu .dropdown-item:hover,
.CheckboxMenu .dropdown-item:active {
  background-color: transparent;
  color: black;
}

.indicator-card {
  border: 1px solid #2f2f7D;
  justify-content:space-between;
  max-width: 27%;
  padding: 15px;
  font-size: small;
  display:flex;
  margin: auto;
  height: 100%;
}

.logo-img {
  height: 48px;
  width: 65%;
  margin: auto;
  max-width: 100%;
  object-fit: scale-down;
}